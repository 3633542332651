import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <div className="column is-10 is-offset-1">
            <h1>お問い合わせありがとうございます！</h1>
            <p>この度はお問い合わせメールをお送りいただきありがとうございます。後ほど、担当者よりご連絡をさせていただきます。今しばらくお待ちくださいますようよろしくお願い申し上げます。</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
